<template>
  <div id="user-list">
    <!-- app drawer -->
    <common-add-new
        :api-urls="apiUrls"
        :slug-name="slug"
        :module-name="moduleName"
        v-model="isAddNewAdminSidebarActive"
        :form-setting="formSetting"
        :role-options="[]"
        @refetch-data="fetchList"
    ></common-add-new>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
          v-for="total in commonTotalLocal"
          :key="total.total"
          cols="12"
          sm="6"
          md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <!--            <v-avatar
                            :color="resolveUserTotalIcon(total.title).color"
                            :class="`v-avatar-light-bg ${resolveUserTotalIcon(total.title).color}&#45;&#45;text`"
                        >
                          <v-icon
                              size="25"
                              :color="resolveUserTotalIcon(total.title).color"
                              class="rounded-0"
                          >
                            {{ resolveUserTotalIcon(total.title).icon }}
                          </v-icon>
                        </v-avatar>-->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
        {{ filters }}
      </v-card-title>

      <v-row class="px-2 ma-0">
        <!-- admin filter -->
        <v-col
            v-for="(tableHeader, index) in tableHeaders.filter(x => x.filter === true)"
            :key="tableHeader.value"
            v-if="tableHeader.filter"
            cols="12"
            sm="4"
        >
          <v-text-field
              v-model="filters[tableHeader.value]"
              :key="index"
              :value="tableHeader.value"
              v-if="!hasFilterOptions(tableHeader)"
              :label="tableHeader.text"
              placeholder="Enter filter value"
              outlined
              clearable
              dense
              hide-details
          />
          <v-select
              v-else
              v-model="filters[index]"
              placeholder="Select Action"
              :items="tableHeader.filterOptions.value"
              :label="tableHeader.text"
              item-text="text"
              item-value="value"
              outlined
              clearable
              dense
              hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
              color="primary"
              class="mb-4 me-3"
              @click.stop="isAddNewAdminSidebarActive = !isAddNewAdminSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New {{ slug }}</span>
          </v-btn>

          <v-btn
              color="secondary"
              outlined
              class="mb-4"
          >
            <v-icon
                size="17"
                class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
          v-model="selectedRows"
          :headers="tableHeaders"
          :items="commonListTable"
          :options.sync="options"
          :server-items-length="totalCommonListTable"
          :loading="loading"
          show-select
          class="table-kitchen-sink"
      >


        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-icon
              small
              class="me-2"
              @click="editItem(item)"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>

        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="isAddNewAdminSidebarActive = !isAddNewAdminSidebarActive"
          >
            Add New
          </v-btn>
        </template>


        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
          <slot :name="scopedSlotName" v-bind="slotData" />
        </template>

        <!-- pass through normal slots -->
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
          <slot :name="slotName" />
        </template>
      </v-data-table>

    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline, mdiPencilOutline,
} from "@mdi/js"
import store from "@/store"
import {onUnmounted, ref} from "@vue/composition-api"

// sidebar
import {avatarText} from "@core/utils/filter"
import AdminListAddNew from "@/views/access-management/admins/admin-list/AdminListAddNew.vue"
import commonStoreModule from "@/views/common/list/standard-list/commonStoreModule"
import useCommonList from "@/views/common/list/standard-list/useCommonList"
import CommonAddNew from "@/views/common/list/standard-list/CommonAddNew"

export default {
  components: {
    CommonAddNew,
    AdminListAddNew,
  },
  props: {
    slug: {
      type: String
    },
    moduleName: {
      type: String
    },
    tableHeaders: {
      type: Array,
      default() {
        return []
      }
    },
    formSetting: {
      type: Array,
      default() {
        return []
      }
    },
    apiUrls: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
  }),
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete()
    },
  },
  methods: {
    hasFilterOptions(tableHeader) {
      return tableHeader.filterOptions && tableHeader.filterOptions.value && tableHeader.filterOptions.value.length > 0
    },
    editItem(item) {
      console.log(item)
      this.isAddNewAdminSidebarActive = true;
    },

    deleteItem(item) {
      this.editedIndex = this.commonListTable.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.commonListTable.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.commonListTable[this.editedIndex], this.editedItem)
      } else {
        this.commonListTable.push(this.editedItem)
      }
      this.close()
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "common-module"

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, commonStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      commonListTable,
      searchQuery,
      filters,
      totalCommonListTable,
      loading,
      options,
      commonTotalLocal,
      selectedRows,
      fetchList,
    } = useCommonList({apiUrls: props.apiUrls})

    const isAddNewAdminSidebarActive = ref(false)

    return {
      commonListTable,
      searchQuery,
      filters,
      totalCommonListTable,
      loading,
      options,
      commonTotalLocal,
      isAddNewAdminSidebarActive,
      selectedRows,
      avatarText,
      fetchList,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
