import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(queryParams.url, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
            .get('/admin/role', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addModule(ctx, adminData) {
      return new Promise((resolve, reject) => {
        axios
          .post(adminData?.url, adminData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
