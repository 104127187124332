import store from "@/store"
import {
	mdiAccountCheckOutline,
	mdiAccountOutline,
	mdiAccountPlusOutline,
	mdiAccountRemoveOutline,
	mdiCogOutline,
	mdiDatabaseOutline,
	mdiDnsOutline,
	mdiPencilOutline,
} from "@mdi/js"
import {ref, watch} from "@vue/composition-api"

export default function usePermissionsList() {
	const permissionListTable = ref([])

	const tableColumns = [
		{text: "NAME", value: "name"},
		{text: "ACTION", value: "action"},
		{text: "SUBJECT", value: "subject", sortable: false},
		{
			text: "ACTIONS",
			value: "actions",
			align: "center",
			sortable: false,
		},
	]

	const searchQuery = ref("")
	const actionFilter = ref(null)
	const subjectFilter = ref(null)
	const totalPermissionListTable = ref(0)
	const loading = ref(false)
	const options = ref({
		sortBy: ["id"],
		sortDesc: [true],
	})
	const permissionTotalLocal = ref([])
	const selectedRows = ref([])

	// fetch data
	const fetchPermissions = () => {
		store
			.dispatch("am-permission/fetchPermissions", {
				q: searchQuery.value,
				options: options.value,
				action: actionFilter.value,
				subject: subjectFilter.value,
			})
			.then(response => {
				const {result, permissionTotal} = response.data

				permissionListTable.value = result.data
				totalPermissionListTable.value = result.total
				permissionTotalLocal.value = permissionTotal

				// remove loading state
				loading.value = false
			})
			.catch(error => {
				console.log(error)
			})
	}

	watch([searchQuery, actionFilter, subjectFilter, options], () => {
		loading.value = true
		selectedRows.value = []
		fetchPermissions()
	})

	// *===============================================---*
	// *--------- UI ---------------------------------------*
	// *===============================================---*

	const resolveUserRoleVariant = role => {
		if (role === "subscriber") return "primary"
		if (role === "author") return "warning"
		if (role === "maintainer") return "success"
		if (role === "editor") return "info"
		if (role === "admin") return "error"

		return "primary"
	}

	const resolveUserRoleIcon = role => {
		if (role === "subscriber") return mdiAccountOutline
		if (role === "author") return mdiCogOutline
		if (role === "maintainer") return mdiDatabaseOutline
		if (role === "editor") return mdiPencilOutline
		if (role === "admin") return mdiDnsOutline

		return mdiAccountOutline
	}

	const resolveUserStatusVariant = status => {
		if (status === "pending") return "warning"
		if (status === "active") return "success"
		if (status === "inactive") return "secondary"

		return "primary"
	}

	const resolveUserTotalIcon = total => {
		if (total === "Total Users") return {icon: mdiAccountOutline, color: "primary"}
		if (total === "Paid Users") return {icon: mdiAccountPlusOutline, color: "error"}
		if (total === "Active Users") return {icon: mdiAccountCheckOutline, color: "success"}
		if (total === "Pending Users") return {icon: mdiAccountRemoveOutline, color: "warning"}

		return {icon: mdiAccountOutline, color: "primary"}
	}

	return {
		permissionListTable,
		tableColumns,
		searchQuery,
		actionFilter,
		subjectFilter,
		totalPermissionListTable,
		loading,
		options,
		permissionTotalLocal,
		selectedRows,
		fetchPermissions,
		resolveUserRoleVariant,
		resolveUserRoleIcon,
		resolveUserStatusVariant,
		resolveUserTotalIcon,
	}
}
