import store from "@/store"
import {
	mdiAccountCheckOutline,
	mdiAccountOutline,
	mdiAccountPlusOutline,
	mdiAccountRemoveOutline,
	mdiCogOutline,
	mdiDatabaseOutline,
	mdiDnsOutline,
	mdiPencilOutline,
} from "@mdi/js"
import {ref, watch} from "@vue/composition-api"

export default function useCommonList(props) {
	const commonListTable = ref([])

	const searchQuery = ref("")
	const filters = ref([])
	const totalCommonListTable = ref(0)
	const loading = ref(false)
	const options = ref({
		sortBy: ["id"],
		sortDesc: [true],
	})
	const commonTotalLocal = ref([])
	const selectedRows = ref([])

	// fetch data
	const fetchList = () => {
		store
			.dispatch("common-module/fetchList", {
				url: props.apiUrls?.list,
				q: searchQuery.value,
				options: options.value,
				filters: filters.value
			})
			.then(response => {
				const {result, adminTotal} = response.data

				commonListTable.value = result.data
				totalCommonListTable.value = result.total
				commonTotalLocal.value = adminTotal

				// remove loading state
				loading.value = false
			})
			.catch(error => {
				console.log(error)
			})
	}

	watch([searchQuery, filters, options], () => {
		loading.value = true
		selectedRows.value = []
		fetchList()
	}, {deep: true})

	// *===============================================---*
	// *--------- UI ---------------------------------------*
	// *===============================================---*

	return {
		commonListTable,
		searchQuery,
		filters,
		totalCommonListTable,
		loading,
		options,
		commonTotalLocal,
		selectedRows,
		fetchList
	}
}
