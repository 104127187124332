<template>
  <v-navigation-drawer
      :value="isAddNewModuleSidebarActive"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
      app
      @input="(val) => $emit('update:is-add-new-module-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New {{slugName}}</span>
        <v-spacer></v-spacer>
        <v-btn
            icon
            small
            @click="$emit('update:is-add-new-module-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="onSubmit"
        >
          <template v-for="(f, index) in formSetting">
            <div :key="index">

              <template v-if="f.type === 'text'">
                <v-text-field
                    v-model="adminData[f.name]"
                    outlined
                    dense
                    :rules="[(f.required ? validators.required : true)]"
                    :label="f.name.replaceAll('_', ' ').toUpperCase()"
                    placeholder=""
                    hide-details="auto"
                    class="mb-6"
                ></v-text-field>
              </template>


              <template v-if="f.type === 'select'">
                <v-select
                    v-model="adminData[f.name]"
                    :rules="[(f.required ? validators.required : true)]"
                    :label="f.name.replaceAll('_', ' ').toUpperCase()"
                    :items="f.options.value"
                    item-text="name"
                    item-value="name"
                    outlined
                    dense
                    hide-details="auto"
                    class="mb-6"
                >
                </v-select>
              </template>


            </div>
          </template>
<!--          <v-text-field
              v-model="adminData.name"
              outlined
              dense
              :rules="[validators.required]"
              label="Admin Name"
              placeholder=""
              hide-details="auto"
              class="mb-6"
          ></v-text-field>
          <v-text-field
              v-model="adminData.email"
              outlined
              dense
              :rules="[validators.required, validators.emailValidator]"
              label="Admin Email"
              placeholder=""
              hide-details="auto"
              class="mb-6"
          ></v-text-field>
          <v-text-field
              v-model="adminData.password"
              outlined
              dense
              :rules="[validators.required]"
              label="Admin Password"
              placeholder=""
              hide-details="auto"
              class="mb-6"
          ></v-text-field>

          <v-select
              v-model="adminData.roles"
              :rules="[validators.required]"
              label="Roles"
              :items="roleOptions"
              item-text="name"
              item-value="name"
              outlined
              dense
              hide-details="auto"
              class="mb-6"
              multiple
          >
          </v-select>-->

          <v-btn
              color="primary"
              class="me-3"
              type="submit"
          >
            Add
          </v-btn>
          <v-btn
              color="secondary"
              outlined
              type="reset"
              @click="resetAdminData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import {mdiClose} from "@mdi/js"
import countries from "@/@fake-db/data/other/countries"
import store from "@/store"
import {ref} from "@vue/composition-api"
import {required, emailValidator} from "@core/utils/validation"

export default {
  model: {
    prop: "isAddNewModuleSidebarActive",
    event: "update:is-add-new-module-sidebar-active",
  },
  props: {
    isAddNewModuleSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    apiUrls: {
      type: Object,
      required: true,
    },
    formSetting: {
      type: Array,
      required: true,
    },
    moduleName: {
      type: String
    },
    slugName: {
      type: String,
      required: true
    }
  },
  methods: {
    async getOptions(form) {
      let x = await form['options']();
      return x;
    }
  },
  setup(props, {emit}) {
    const blankAdminData = {
      name: "",
      email: "",
      password: "",
      roles: []
    }

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const adminData = ref(JSON.parse(JSON.stringify(blankAdminData)))
    const resetAdminData = () => {
      adminData.value = JSON.parse(JSON.stringify(blankAdminData))
      resetForm()
      emit("update:is-add-new-module-sidebar-active", false)
    }

    const onSubmit = () => {
      if (valid.value) {
        adminData.value.url = props?.apiUrls?.add;
        store.dispatch("common-module/addModule", adminData.value).then(() => {
          emit("refetch-data")
          emit("update:is-add-new-admin-sidebar-active", false)
        })

        resetAdminData()
      } else {
        validate()
      }
    }

    return {
      resetAdminData,
      form,
      onSubmit,
      adminData,
      valid,
      validate,

      // validation
      validators: {required, emailValidator},
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
